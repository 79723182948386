@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: "Tenor Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
